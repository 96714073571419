<template>
  <div class="become-tco-container">
    <WelcomeMessage :textPath="'tco.becomeTco.welcomeBanner'" />

    <h1 class="text-center mt-6">{{ $t("tco.becomeTco.title") }}</h1>

    <v-row>
      <v-col cols="12" md="6">
        <!-- diventa carta oro digitale -->
        <v-card
          outlined
          class="primary--text d-flex flex-column justify-center align-center h-100"
        >
          <div class="avaiable-banner">
            {{ $t("tco.becomeTco.digital.topText") }}
          </div>
          <v-card-title class="mt-5">
            <span
              class="text-center"
              v-html="$t('tco.becomeTco.digital.title')"
            ></span>
          </v-card-title>

          <v-card-text>
            <img
              class="d-block mb-6"
              max-width="300"
              src="/img_layout/become_tco/tco-digital.png"
              alt="Immagine carta oro digitale"
            />
            <span
              class="text-body-1"
              v-html="$t('tco.becomeTco.digital.descr')"
            ></span>
          </v-card-text>
          <v-card-actions>
            <v-btn
              @click="letsBecomeTco(true)"
              color="primary"
              depressed
              x-large
            >
              <span v-html="$t('tco.becomeTco.digital.btn')"></span>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col cols="12" md="6">
        <!-- diventa carta oro fisica -->
        <v-card
          outlined
          class="primary--text d-flex flex-column justify-center align-center h-100"
        >
          <v-card-title class="mt-5">
            <span
              class="text-center"
              v-html="$t('tco.becomeTco.phisical.title')"
            ></span>
          </v-card-title>

          <v-card-text>
            <img
              class="d-block mb-6"
              max-width="300"
              src="/img_layout/become_tco/tco-phisic.png"
              alt="Immagine tessera carta oro"
            />
            <span
              class="text-body-1"
              v-html="$t('tco.becomeTco.phisical.descr')"
            ></span>
          </v-card-text>
          <v-card-actions>
            <v-btn
              color="primary"
              depressed
              x-large
              @click="letsBecomeTco(false)"
            >
              <span v-html="$t('tco.becomeTco.phisical.btn')"></span>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>
<style lang="scss">
.become-tco-container {
  .avaiable-banner {
    position: absolute;
    font-size: 18px;
    font-weight: bold;
    padding: 5px 10px;
    top: 0;
    right: 0;
    z-index: 1;
    background-image: linear-gradient(270deg, #fbb61f, #ffd83a, #fbf7d6);
  }
  .v-card__title {
    font-size: 20px;
    .title-gold {
      color: $gold;
    }
  }
  .v-card__text {
    img {
      width: 100%;
      max-width: 474px;
    }
  }
}
.verification-dialog .v-card__actions .v-btn:first-of-type {
  display: none !important;
}
</style>
<script>
import WelcomeMessage from "@/components/cardOro/WelcomeMessage.vue";

import TigrosCardOroService from "@/service/tigrosCardOroService";

import { mapActions } from "vuex";
import { getPersonInfo } from "~/service/ebsn";

export default {
  name: "BecomeTco",
  components: { WelcomeMessage },
  data() {
    return {};
  },

  methods: {
    ...mapActions({
      getCart: "cart/getCart"
    }),
    async letsBecomeTco(digitalTypeCard) {
      //che privacy flags
      await this.getCart();
      console.log(this.$store.state.cart.cart.user);
      if (getPersonInfo(this.$store.state.cart.cart.user.person, 7) != "true") {
        //open popup
        let res = await this.$dialog.confirm({
          text: global.vm.$t("message.requiresPrivacyFlag"),
          class: "verification-dialog"
        });
        if (res) {
          this.$router.push({
            name: "ProfileUpdate",
            query: { anchor: "privacyDataForm" }
          });
        } else {
          return;
        }

        return;
      }
      // digitalTypeCard = true -> digitale , else phisical
      let res = await TigrosCardOroService.selectTypeCardTco(digitalTypeCard);
      if (res && res.value && res.value.result_update) {
        await this.getCart();
        if (res.value.redirect_url == "tco_digital") {
          this.$router.push({
            path: "/card-oro/welcome-digital-tco",
            name: "WelcomeDigitalTco"
          });
        } else {
          this.$router.push({
            name: "WelcomePhisicalTco"
          });
        }
      }
    }
  }
};
</script>
